export default [
  // 20221010101104
  // https://api.github.com/users/cetinsangu/following

  {
    login: "jeunerturk",
    id: 34372000,
    node_id: "MDQ6VXNlcjM0MzcyMDAw",
    avatar_url: "https://avatars.githubusercontent.com/u/34372000?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/jeunerturk",
    html_url: "https://github.com/jeunerturk",
    followers_url: "https://api.github.com/users/jeunerturk/followers",
    following_url:
      "https://api.github.com/users/jeunerturk/following{/other_user}",
    gists_url: "https://api.github.com/users/jeunerturk/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/jeunerturk/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/jeunerturk/subscriptions",
    organizations_url: "https://api.github.com/users/jeunerturk/orgs",
    repos_url: "https://api.github.com/users/jeunerturk/repos",
    events_url: "https://api.github.com/users/jeunerturk/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/jeunerturk/received_events",
    type: "User",
    site_admin: false,
  },
  {
    login: "omerfee",
    id: 35680891,
    node_id: "MDQ6VXNlcjM1NjgwODkx",
    avatar_url: "https://avatars.githubusercontent.com/u/35680891?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/omerfee",
    html_url: "https://github.com/omerfee",
    followers_url: "https://api.github.com/users/omerfee/followers",
    following_url:
      "https://api.github.com/users/omerfee/following{/other_user}",
    gists_url: "https://api.github.com/users/omerfee/gists{/gist_id}",
    starred_url: "https://api.github.com/users/omerfee/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/omerfee/subscriptions",
    organizations_url: "https://api.github.com/users/omerfee/orgs",
    repos_url: "https://api.github.com/users/omerfee/repos",
    events_url: "https://api.github.com/users/omerfee/events{/privacy}",
    received_events_url: "https://api.github.com/users/omerfee/received_events",
    type: "User",
    site_admin: false,
  },
  {
    login: "perperunas",
    id: 44569786,
    node_id: "MDQ6VXNlcjQ0NTY5Nzg2",
    avatar_url: "https://avatars.githubusercontent.com/u/44569786?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/perperunas",
    html_url: "https://github.com/perperunas",
    followers_url: "https://api.github.com/users/perperunas/followers",
    following_url:
      "https://api.github.com/users/perperunas/following{/other_user}",
    gists_url: "https://api.github.com/users/perperunas/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/perperunas/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/perperunas/subscriptions",
    organizations_url: "https://api.github.com/users/perperunas/orgs",
    repos_url: "https://api.github.com/users/perperunas/repos",
    events_url: "https://api.github.com/users/perperunas/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/perperunas/received_events",
    type: "User",
    site_admin: false,
  },
  {
    login: "canokulmus",
    id: 49879416,
    node_id: "MDQ6VXNlcjQ5ODc5NDE2",
    avatar_url: "https://avatars.githubusercontent.com/u/49879416?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/canokulmus",
    html_url: "https://github.com/canokulmus",
    followers_url: "https://api.github.com/users/canokulmus/followers",
    following_url:
      "https://api.github.com/users/canokulmus/following{/other_user}",
    gists_url: "https://api.github.com/users/canokulmus/gists{/gist_id}",
    starred_url:
      "https://api.github.com/users/canokulmus/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/canokulmus/subscriptions",
    organizations_url: "https://api.github.com/users/canokulmus/orgs",
    repos_url: "https://api.github.com/users/canokulmus/repos",
    events_url: "https://api.github.com/users/canokulmus/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/canokulmus/received_events",
    type: "User",
    site_admin: false,
  },
  {
    login: "aybalado",
    id: 80275136,
    node_id: "MDQ6VXNlcjgwMjc1MTM2",
    avatar_url: "https://avatars.githubusercontent.com/u/80275136?v=4",
    gravatar_id: "",
    url: "https://api.github.com/users/aybalado",
    html_url: "https://github.com/aybalado",
    followers_url: "https://api.github.com/users/aybalado/followers",
    following_url:
      "https://api.github.com/users/aybalado/following{/other_user}",
    gists_url: "https://api.github.com/users/aybalado/gists{/gist_id}",
    starred_url: "https://api.github.com/users/aybalado/starred{/owner}{/repo}",
    subscriptions_url: "https://api.github.com/users/aybalado/subscriptions",
    organizations_url: "https://api.github.com/users/aybalado/orgs",
    repos_url: "https://api.github.com/users/aybalado/repos",
    events_url: "https://api.github.com/users/aybalado/events{/privacy}",
    received_events_url:
      "https://api.github.com/users/aybalado/received_events",
    type: "User",
    site_admin: false,
  },
];
