export default // 20221010100643
// https://api.github.com/users/cetinsangu

{
  login: "cetinsangu",
  id: 41289806,
  node_id: "MDQ6VXNlcjQxMjg5ODA2",
  avatar_url: "https://avatars.githubusercontent.com/u/41289806?v=4",
  gravatar_id: "",
  url: "https://api.github.com/users/cetinsangu",
  html_url: "https://github.com/cetinsangu",
  followers_url: "https://api.github.com/users/cetinsangu/followers",
  following_url:
    "https://api.github.com/users/cetinsangu/following{/other_user}",
  gists_url: "https://api.github.com/users/cetinsangu/gists{/gist_id}",
  starred_url: "https://api.github.com/users/cetinsangu/starred{/owner}{/repo}",
  subscriptions_url: "https://api.github.com/users/cetinsangu/subscriptions",
  organizations_url: "https://api.github.com/users/cetinsangu/orgs",
  repos_url: "https://api.github.com/users/cetinsangu/repos",
  events_url: "https://api.github.com/users/cetinsangu/events{/privacy}",
  received_events_url:
    "https://api.github.com/users/cetinsangu/received_events",
  type: "User",
  site_admin: false,
  name: "Çetin Sanğu",
  company: "Open to Work",
  blog: "www.cetinsangu.com",
  location: "Turkey",
  email: null,
  hireable: null,
  bio: "I'm Cetin, 23 years old and living in Kars/TURKEY. I have a degree in law. My primary field of interest is creating websites. Focusing ReactJS right now. ",
  twitter_username: "littledevone_",
  public_repos: 30,
  public_gists: 0,
  followers: 3,
  following: 3,
  created_at: "2018-07-16T10:35:26Z",
  updated_at: "2022-09-14T11:40:13Z",
};
